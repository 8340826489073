var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card border-0"},[_c('div',{staticClass:"card-header f-w-600"},[_c('vxe-button',{staticClass:"m-r-20",attrs:{"status":"primary","content":"新增"},on:{"click":_vm.insertREvent}}),_vm._v(" 危化品使用情况 ")],1),_c('div',{staticClass:"card-body height-400 m-0 p-0"},[_c('form',{attrs:{"method":"POST"}},[_c('vxe-table',{ref:"DangerproductTable",attrs:{"border":"","resizable":"","show-overflow":"","keep-source":"","loading":_vm.loading,"height":"350","data":_vm.DangerproductTableData,"edit-rules":_vm.validRules,"layouts":[
          'PrevPage',
          'JumpNumber',
          'NextPage',
          'FullJump',
          'Sizes',
          'Total' ],"edit-config":{
          trigger: 'click',
          mode: 'row',
          showUpdateStatus: true,
          showInsertStatus: true,
        }}},[_c('vxe-column',{attrs:{"type":"checkbox","width":"60"}}),_c('vxe-column',{attrs:{"field":"dictId","title":"危化品种类","edit-render":{}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.formatdictId(row.dictId)))])]}},{key:"edit",fn:function(ref){
        var row = ref.row;
return [_c('vxe-select',{attrs:{"type":"text","options":_vm.DangerproductDict,"transfer":""},model:{value:(row.dictId),callback:function ($$v) {_vm.$set(row, "dictId", $$v)},expression:"row.dictId"}})]}}])}),_c('vxe-column',{attrs:{"field":"monthUsed","title":"月使用量","edit-render":{},"width":"200"},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
        var row = ref.row;
return [_c('vxe-input',{model:{value:(row.monthUsed),callback:function ($$v) {_vm.$set(row, "monthUsed", $$v)},expression:"row.monthUsed"}})]}}])}),_c('vxe-column',{attrs:{"field":"dayStorage","title":"日常存储量","edit-render":{},"width":"200"},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
        var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"text","digits":2},model:{value:(row.dayStorage),callback:function ($$v) {_vm.$set(row, "dayStorage", $$v)},expression:"row.dayStorage"}})]}}])}),_c('vxe-column',{attrs:{"title":"企业id","field":"businessId","visible":false},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
        var row = ref.row;
return [_c('vxe-input',{model:{value:(row.businessId),callback:function ($$v) {_vm.$set(row, "businessId", $$v)},expression:"row.businessId"}})]}}])}),_c('vxe-column',{attrs:{"title":"操作","fixed":"right","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var row = ref.row;
return [_c('vxe-button',{attrs:{"status":"warning","content":"删除"},on:{"click":function($event){return _vm.removeRRowEvent(row)}}}),_c('vxe-button',{attrs:{"status":"success","content":"保存"},on:{"click":function($event){return _vm.saveREvent(row)}}})]}}])})],1),_c('vxe-pager',{attrs:{"border":"","icon-prev-page":"fa fa-angle-left","icon-jump-prev":"fa fa-angle-double-left","icon-jump-next":"fa fa-angle-double-right","icon-next-page":"fa fa-angle-right","icon-jump-more":"fa fa-ellipsis-h","loading":_vm.loading,"current-page":_vm.RtablePage.currentPage,"page-size":_vm.RtablePage.pageSize,"total":_vm.RtablePage.totalResult,"layouts":[
          'PrevPage',
          'JumpNumber',
          'NextPage',
          'FullJump',
          'Sizes',
          'Total' ]},on:{"page-change":_vm.handlePageChange}})],1)]),_c('div',{staticClass:"card-footer text-right f-w-600"},[_c('button',{staticClass:"btn btn-sm btn-default",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" ❌关闭 ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }