<template>
  <div class="card border-0">
    <div class="card-header f-w-600">
      <vxe-button
        class="m-r-20"
        status="primary"
        content="新增"
        @click="insertTEvent"
      ></vxe-button
      >特种作业人员
    </div>
    <div class="card-body height-400 m-0 p-0">
      <form method="POST">
        <vxe-table
          border
          resizable
          show-overflow
          keep-source
          :loading="loading"
          ref="SpecialoperatorTable"
          height="350"
          :data="TtableData"
          :edit-rules="validRules"
          :layouts="[
            'PrevPage',
            'JumpNumber',
            'NextPage',
            'FullJump',
            'Sizes',
            'Total',
          ]"
          :edit-config="{
            trigger: 'click',
            mode: 'row',
            showUpdateStatus: true,
            showInsertStatus: true,
          }"
        >
          <vxe-column type="checkbox" width="60"></vxe-column>
          <vxe-column field="name" title="姓名" :edit-render="{}" width="200">
            <template #edit="{ row }">
              <vxe-input v-model="row.name" type="text"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column
            field="isCard"
            title="持证情况"
            :edit-render="{}"
            width="100"
          >
            <template #default="{ row }">
              <span>{{ row.isCard ? "是" : "否" }}</span>
            </template>
            <template #edit="{ row }">
              <vxe-select
                v-model="row.isCard"
                type="text"
                :options="IsCardList"
                transfer
              ></vxe-select>
            </template>
          </vxe-column>
          <vxe-column field="tel" title="电话" :edit-render="{}" width="200">
            <template #edit="{ row }">
              <vxe-input v-model="row.tel"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column
            field="cardValidity"
            title="证书有效期"
            :edit-render="{}"
            width="200"
          >
            <template #edit="{ row }">
              <vxe-input v-model="row.cardValidity" type="date"></vxe-input>
            </template>
          </vxe-column>

          <vxe-column
            title="证书号"
            width="240"
            field="cardNum"
            :edit-render="{}"
          >
            <template #edit="{ row }">
              <vxe-input type="text" v-model="row.cardNum"></vxe-input>
            </template>
          </vxe-column>

          <vxe-column
            title="企业id"
            field="businessId"
            :visible="false"
            width="200"
          >
            <template #edit="{ row }">
              <vxe-input v-model="row.businessId"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column title="特种作业人员ID" field="id" :visible="false">
            <template #edit="{ row }">
              <vxe-input v-model="row.id"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column title="操作" fixed="right" width="190">
            <template #default="{ row }">
              <vxe-button
                status="warning"
                content="删除"
                @click="removeTRowEvent(row)"
              ></vxe-button>
              <vxe-button
                status="success"
                content="保存"
                @click="saveTEvent(row)"
              ></vxe-button>
            </template>
          </vxe-column>
        </vxe-table>
        <vxe-pager
          border
          icon-prev-page="fa fa-angle-left"
          icon-jump-prev="fa fa-angle-double-left"
          icon-jump-next="fa fa-angle-double-right"
          icon-next-page="fa fa-angle-right"
          icon-jump-more="fa fa-ellipsis-h"
          :loading="loading"
          :current-page="TtablePage.currentPage"
          :page-size="TtablePage.pageSize"
          :total="TtablePage.totalResult"
          :layouts="[
            'PrevPage',
            'JumpNumber',
            'NextPage',
            'FullJump',
            'Sizes',
            'Total',
          ]"
          @page-change="handleTPageChange"
        >
        </vxe-pager>
      </form>
    </div>
    <div class="card-footer text-right f-w-600">
      <!-- <a @click="onOK" type="submit" class="btn btn-sm btn-primary m-r-5"> 确定 </a> -->
      <!-- <button type="submit" @click="checkForm" class="btn btn-sm btn-default">
          确定
        </button> -->
      <button
        type="button"
        @click="$emit('close')"
        class="btn btn-sm btn-default"
      >
        ❌关闭
      </button>
    </div>
  </div>
</template>
<script>
import { businessApi } from "@/api/system/businessApi";

export default {
  props: ["SpecialoperatorListid"],
  data() {
    return {
      TtablePage: {
        currentPage: 1,
        pageSize: 10,
        totalResult: 0,
      },
      TtableData: [],
      loading: false,
      validRules: {
        Name: [{ required: true, message: "名称必须填写" }],
      },
      IsCardList: [
        { label: "是", value: true },
        { label: "否", value: false },
      ],
    };
  },
  mounted() {
    if (this.SpecialoperatorListid) {
      this.GetSpecialoperatorList(this.SpecialoperatorListid);
    }
  },
  methods: {
    ToastSuccess(res) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      var title = [];
      if (res.message == "Success") {
        title = "保存成功";
      } else {
        title = res.message;
      }
      Toast.fire({
        icon: "success",
        title: title,
      });
    },
    ToastWarning(res) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      var title = [];
      if (res.message == "False") {
        title = "保存出错";
      } else {
        title = res.message;
      }
      Toast.fire({
        icon: "warning",
        title: title,
      });
    },
    //获取特种作业人员列表
    GetSpecialoperatorList(id) {
      let that = this;
      that.loading = true;
      businessApi
        .GetSpecialoperatorList({
          //入参为地址栏传入的ID值
          SkipCount:
            (that.TtablePage.currentPage - 1) * that.TtablePage.pageSize,
          MaxResultCount: that.TtablePage.pageSize,
          Sorting: "",
          BusinessId: id,
        })
        .then((res) => that.GetSpecialoperatorListSuccess(that, res))
        .finally(() => {
          that.loading = false;
        });
    },
    GetSpecialoperatorListSuccess(that, res) {
      if (res.success) {
        that.TtableData = res.data.items;
        that.TtableData.isMain ? "是" : "否";
        that.TtablePage.totalResult = res.data.totalCount;
      } else {
        console.log(res);
      }
    },
    //特种作业人员列表新增
    async insertTEvent() {
      const $table = this.$refs.SpecialoperatorTable;
      const newRecord = {
        BusinessId: this.SpecialoperatorListid,
        Name: "",
        IdCard: "",
        Tel: "",
        IsCard: "",
        CardNum: "",
        CardValidity: "",
        IsMain: true,
      };
      const { row: newRow } = await $table.insert(newRecord);
      await $table.setActiveRow(newRow);
    },
    saveTEvent(row) {
      const TtableData = {
        businessId: this.SpecialoperatorListid,
        name: row.name,
        idCard: row.idCard,
        tel: row.tel,
        isCard: row.isCard,
        cardNum: row.cardNum,
        cardValidity: row.cardValidity,
        isMain: row.isMain,
      };
      let that = this;
      //特种作业人员
      if (row.id) {
        businessApi
          .UpdateSpecialoperatorList(row)
          .then((res) => that.UpdateSpecialoperatorListSuccess(that, res))
          .finally(() => {});
      } else {
        businessApi
          .CreateSpecialoperatorList(TtableData)
          .then((res) => that.CreateSpecialoperatorListSuccess(that, res))
          .finally(() => {});
        console.log(that);
      }
    },
    removeTRowEvent(row) {
      this.$swal({
        // title: "Are you sure?",
        text: "是否确定删除?",
        type: "info",
        icon: "warning",
        showCancelButton: true, //确定按钮
        buttonsStyling: false,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        confirmButtonClass: "btn m-r-5 btn-" + "info" + "", //按钮颜色设置
        cancelButtonClass: "btn btn-default", //按钮类型设置
      }).then((isConfirm) => {
        try {
          //判断 是否 点击的 确定按钮
          if (isConfirm.value) {
            let that = this;
            if (row.id) {
              businessApi
                .DeleteSpecialoperatorList({ id: row.id })
                .then((res) =>
                  that.DeleteSpecialoperatorListSuccess(that, res, row)
                )
                .finally(() => {});
            } else {
              const $table = this.$refs.SpecialoperatorTable;
              $table.remove(row);
            }
          } else {
            console.log(isConfirm);
          }
        } catch (e) {
          alert(e);
        }
      });
    },
    CreateSpecialoperatorListSuccess(that, res) {
      if (res.success) {
        that.ToastSuccess(res);
        this.$emit("close");
      } else {
        that.ToastWarning(res);
      }
    },
    UpdateSpecialoperatorListSuccess(that, res) {
      if (res.success) {
        that.ToastSuccess(res);
        this.$emit("close");
      } else {
        that.ToastWarning(res);
      }
    },
    DeleteSpecialoperatorListSuccess(that, res, row) {
      if (res.success) {
        const $table = this.$refs.SpecialoperatorTable;
        $table.remove(row);
        res.message = "删除成功";
        that.ToastSuccess(res);
        console.log(res);
      } else {
        console.log(res);
      }
    },
    handleTPageChange({ currentPage, pageSize }) {
      this.TtablePage.currentPage = currentPage;
      this.TtablePage.pageSize = pageSize;
      //改变分页参数，重新请求Api
      this.GetSpecialoperatorList();
    },
  },
};
</script>
