<template>
  <div class="card border-0">
    <div class="card-header f-w-600">
      <vxe-button
        class="m-r-20"
        status="primary"
        content="新增"
        @click="insertREvent"
      ></vxe-button>
      容压器
    </div>
    <div class="card-body height-400 m-0 p-0">
      <form method="POST">
        <vxe-table
          border
          resizable
          show-overflow
          keep-source
          :loading="loading"
          ref="PressurevesselTable"
          height="350"
          :data="RtableData"
          :edit-rules="validRules"
          :layouts="[
            'PrevPage',
            'JumpNumber',
            'NextPage',
            'FullJump',
            'Sizes',
            'Total',
          ]"
          :edit-config="{
            trigger: 'click',
            mode: 'row',
            showUpdateStatus: true,
            showInsertStatus: true,
          }"
        >
          <vxe-column type="checkbox" width="60"></vxe-column>
          <vxe-column field="dictId" title="容压器" :edit-render="{}">
            <!-- 请求数据字典，但是页面显示ID,显示不了label -->
            <template #default="{ row }">
              <span>{{ formatdictId(row.dictId) }}</span>
            </template>
            <template #edit="{ row }">
              <!-- <dictSelect
                :show="false"
                :value="row.dictId"
                @select="regionIdIdSelect"
                :pid="'3a019cfc-ef88-6b72-7bc4-f7c04aa3e94c'"
              ></dictSelect> -->
              <vxe-select
                v-model="row.dictId"
                type="text"
                :options="SpecialEquipmentDict"
                transfer
              ></vxe-select>
            </template>
            <!-- 通过formatdictId显示 -->
            <!-- <template #default="{ row }">
                    <span>{{ formatdictId(row.dictId) }}</span>
                  </template>
                  <template #edit="{ row }">
                    <vxe-select
                      v-model="row.dictId"
                      type="text"
                      :options="dictId"
                      transfer
                    ></vxe-select>
                  </template> -->
          </vxe-column>
          <vxe-column field="num" title="数量" :edit-render="{}" width="200">
            <template #edit="{ row }">
              <vxe-input v-model="row.num" type="int"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column field="unit" title="单位" :edit-render="{}" width="200">
            <template #edit="{ row }">
              <vxe-input v-model="row.unit" type="text" :digits="2"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column title="企业id" field="businessId" :visible="false">
            <template #edit="{ row }">
              <vxe-input v-model="row.businessId"></vxe-input>
            </template>
          </vxe-column>

          <vxe-column title="操作" fixed="right" width="150">
            <template #default="{ row }">
              <vxe-button
                status="warning"
                content="删除"
                @click="removeRRowEvent(row)"
              ></vxe-button>
              <vxe-button
                status="success"
                content="保存"
                @click="saveREvent(row)"
              ></vxe-button>
            </template>
          </vxe-column>
        </vxe-table>
        <vxe-pager
          border
          icon-prev-page="fa fa-angle-left"
          icon-jump-prev="fa fa-angle-double-left"
          icon-jump-next="fa fa-angle-double-right"
          icon-next-page="fa fa-angle-right"
          icon-jump-more="fa fa-ellipsis-h"
          :loading="loading"
          :current-page="RtablePage.currentPage"
          :page-size="RtablePage.pageSize"
          :total="RtablePage.totalResult"
          :layouts="[
            'PrevPage',
            'JumpNumber',
            'NextPage',
            'FullJump',
            'Sizes',
            'Total',
          ]"
          @page-change="handlePageChange"
        >
        </vxe-pager>
      </form>
    </div>
    <div class="card-footer text-right f-w-600">
      <!-- <a @click="onOK" type="submit" class="btn btn-sm btn-primary m-r-5"> 确定 </a> -->
      <!-- <button type="submit" @click="checkForm" class="btn btn-sm btn-default">
          确定
        </button> -->
      <button
        type="button"
        @click="$emit('close')"
        class="btn btn-sm btn-default"
      >
        ❌关闭
      </button>
    </div>
  </div>
</template>
<script>
import { businessApi } from "@/api/system/businessApi";
import { dictApi } from "@/api/system/dictApi";
// import dictSelect from "@/components/dictSelect/index.vue";
export default {
  props: ["PressurevesselListid"],
  // components: {
  //   dictSelect,
  // },
  data() {
    return {
      // 容压器表格分页设置
      RtablePage: {
        currentPage: 1,
        pageSize: 10,
        totalResult: 0,
      },
      //   表格数据源
      RtableData: [],
      //容压器数据字典
      SpecialEquipmentDict: [],
      loading: false,
      validRules: {
        Name: [{ required: true, message: "名称必须填写" }],
      },
      formatdictId(value) {
        if (this.SpecialEquipmentDict.length > 0) {
          let label = "";
          //循环数据字典显示名称
          this.SpecialEquipmentDict.forEach(function (item) {
            if (value == item.value) {
              label = item.label;
            }
          });
          return label;
          //   if (value == this.SpecialEquipmentDict[0].value) {
          //     return this.SpecialEquipmentDict[0].label;
          //   }
          //   if (value == this.SpecialEquipmentDict[1].value) {
          //     return this.SpecialEquipmentDict[1].label;
          //   }
          //   if (value == this.SpecialEquipmentDict[2].value) {
          //     return this.SpecialEquipmentDict[2].label;
          //   }
          //   return "";
        }
      },
    };
  },
  mounted() {
    if (this.PressurevesselListid) {
      this.GetPressurevesselList(this.PressurevesselListid);
      this.readSpecialEquipmentDict();
    }
  },
  methods: {
    ToastSuccess(res) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      var title = [];
      if (res.message == "Success") {
        title = "保存成功";
      } else {
        title = res.message;
      }
      Toast.fire({
        icon: "success",
        title: title,
      });
    },
    ToastWarning(res) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      var title = [];
      if (res.message == "False") {
        title = "保存出错";
      } else {
        title = res.message;
      }
      Toast.fire({
        icon: "warning",
        title: title,
      });
    },
    //获取容压器列表
    GetPressurevesselList(id) {
      let that = this;
      that.loading = true;
      businessApi
        .GetPressurevesselList({
          //入参为地址栏传入的ID值
          SkipCount:
            (that.RtablePage.currentPage - 1) * that.RtablePage.pageSize,
          MaxResultCount: that.RtablePage.pageSize,
          Sorting: "",
          BusinessId: id,
        })
        .then((res) => that.GetPressurevesselListSuccess(that, res))
        .finally(() => {
          that.loading = false;
        });
    },
    GetPressurevesselListSuccess(that, res) {
      if (res.success) {
        that.RtableData = res.data.items;
        that.RtablePage.totalResult = res.data.totalCount;
      } else {
        console.log(res);
      }
    },
    //容压器列表新增
    async insertREvent() {
      if (this.PressurevesselListid) {
        const $table = this.$refs.PressurevesselTable;
        const newRecord = {
          BusinessId: this.id,
          DictId: "",
          Num: "",
          Unit: "",
        };
        const { row: newRow } = await $table.insert(newRecord);
        await $table.setActiveRow(newRow);
      } else {
        alert("请保存企业后新增！");
      }
    },
    saveREvent(row) {
      const RtableData = {
        businessId: this.PressurevesselListid,
        dictId: row.dictId,
        num: row.num,
        unit: row.unit,
      };
      let that = this;
      //容压器
      if (row.id) {
        businessApi
          .UpdatePressurevessel(row)
          .then((res) => that.UpdatePressurevesselSuccess(that, res))
          .finally(() => {});
      } else {
        businessApi
          .CreatePressurevessel(RtableData)
          .then((res) => that.CreatePressurevesselSuccess(that, res))
          .finally(() => {});
        console.log(that);
      }
    },
    CreatePressurevesselSuccess(that, res) {
      if (res.success) {
        that.ToastSuccess(res);
        this.$emit("close");
      } else {
        that.ToastWarning(res);
      }
    },
    UpdatePressurevesselSuccess(that, res) {
      if (res.success) {
        that.ToastSuccess(res);
        this.$emit("close");
      } else {
        that.ToastWarning(res);
      }
    },
    removeRRowEvent(row) {
      this.$swal({
        // title: "Are you sure?",
        text: "是否确定删除?",
        type: "info",
        icon: "warning",
        showCancelButton: true, //确定按钮
        buttonsStyling: false,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        confirmButtonClass: "btn m-r-5 btn-" + "info" + "", //按钮颜色设置
        cancelButtonClass: "btn btn-default", //按钮类型设置
      }).then((isConfirm) => {
        try {
          //判断 是否 点击的 确定按钮
          if (isConfirm.value) {
            let that = this;
            if (row.id) {
              businessApi
                .DeletePressurevessel({ id: row.id })
                .then((res) => that.DeletePressurevesselSuccess(that, res, row))
                .finally(() => {});
            } else {
              const $table = this.$refs.PressurevesselTable;
              $table.remove(row);
            }
          } else {
            console.log(isConfirm);
          }
        } catch (e) {
          alert(e);
        }
      });
    },
    DeletePressurevesselSuccess(that, res, row) {
      if (res.success) {
        const $table = this.$refs.PressurevesselTable;
        $table.remove(row);
        res.message = "删除成功";
        that.ToastSuccess(res);
        console.log(res);
      } else {
        console.log(res);
      }
    },
    handlePageChange({ currentPage, pageSize }) {
      this.RtablePage.currentPage = currentPage;
      this.RablePage.pageSize = pageSize;
      //改变分页参数，重新请求Api
      this.GetPressurevesselList();
    },
    //读取[特种设备]数据字典
    readSpecialEquipmentDict() {
      let that = this;
      let options = [];
      that.SpecialEquipmentDict = [];
      dictApi
        .getDictUrl({
          id: "3a01a6a4-e976-402d-cd14-d2c50688e89b",
          bChild: false,
        })
        .then((res) => {
          // console.log('readSpecialEquipmentDict',res.data)
          res.data.forEach((element) => {
            options.push({
              label: element.name,
              value: element.id,
            });
          });
          that.SpecialEquipmentDict = options;
        });
    },
  },
};
</script>
