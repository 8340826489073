<template>
  <div class="card border-0">
    <div class="card-header f-w-600">
      <vxe-button
        class="m-r-20"
        status="primary"
        content="新增"
        @click="insertREvent"
      ></vxe-button>
      危化品使用情况
    </div>
    <div class="card-body height-400 m-0 p-0">
      <form method="POST">
        <vxe-table
          border
          resizable
          show-overflow
          keep-source
          :loading="loading"
          ref="DangerproductTable"
          height="350"
          :data="DangerproductTableData"
          :edit-rules="validRules"
          :layouts="[
            'PrevPage',
            'JumpNumber',
            'NextPage',
            'FullJump',
            'Sizes',
            'Total',
          ]"
          :edit-config="{
            trigger: 'click',
            mode: 'row',
            showUpdateStatus: true,
            showInsertStatus: true,
          }"
        >
          <vxe-column type="checkbox" width="60"></vxe-column>
          <vxe-column
            field="dictId"
            title="危化品种类"
            :edit-render="{}"
          >
            <!-- 请求数据字典，但是页面显示ID,显示不了label -->
            <template #default="{ row }">
              <span>{{ formatdictId(row.dictId) }}</span>
            </template>
            <template #edit="{ row }">
              <vxe-select
                v-model="row.dictId"
                type="text"
                :options="DangerproductDict"
                transfer
              ></vxe-select>
            </template>
          </vxe-column>
          <vxe-column
            field="monthUsed"
            title="月使用量"
            :edit-render="{}"
            width="200"
          >
            <template #edit="{ row }">
              <vxe-input v-model="row.monthUsed"></vxe-input>
            </template>
          </vxe-column>
          <vxe-column
            field="dayStorage"
            title="日常存储量"
            :edit-render="{}"
            width="200"
          >
            <template #edit="{ row }">
              <vxe-input
                v-model="row.dayStorage"
                type="text"
                :digits="2"
              ></vxe-input>
            </template>
          </vxe-column>
          <vxe-column title="企业id" field="businessId" :visible="false">
            <template #edit="{ row }">
              <vxe-input v-model="row.businessId"></vxe-input>
            </template>
          </vxe-column>

          <vxe-column title="操作" fixed="right" width="150">
            <template #default="{ row }">
              <vxe-button
                status="warning"
                content="删除"
                @click="removeRRowEvent(row)"
              ></vxe-button>
              <vxe-button
                status="success"
                content="保存"
                @click="saveREvent(row)"
              ></vxe-button>
            </template>
          </vxe-column>
        </vxe-table>
        <vxe-pager
          border
          icon-prev-page="fa fa-angle-left"
          icon-jump-prev="fa fa-angle-double-left"
          icon-jump-next="fa fa-angle-double-right"
          icon-next-page="fa fa-angle-right"
          icon-jump-more="fa fa-ellipsis-h"
          :loading="loading"
          :current-page="RtablePage.currentPage"
          :page-size="RtablePage.pageSize"
          :total="RtablePage.totalResult"
          :layouts="[
            'PrevPage',
            'JumpNumber',
            'NextPage',
            'FullJump',
            'Sizes',
            'Total',
          ]"
          @page-change="handlePageChange"
        >
        </vxe-pager>
      </form>
    </div>
    <div class="card-footer text-right f-w-600">
      <!-- <a @click="onOK" type="submit" class="btn btn-sm btn-primary m-r-5"> 确定 </a> -->
      <!-- <button type="submit" @click="checkForm" class="btn btn-sm btn-default">
          确定
        </button> -->
      <button
        type="button"
        @click="$emit('close')"
        class="btn btn-sm btn-default"
      >
        ❌关闭
      </button>
    </div>
  </div>
</template>
<script>
import { businessApi } from "@/api/system/businessApi";
import { dictApi } from "@/api/system/dictApi";
export default {
  props: ["DangerproductListid"],
  data() {
    return {
      // 危化品使用情况表格分页设置
      RtablePage: {
        currentPage: 1,
        pageSize: 10,
        totalResult: 0,
      },
      //   表格数据源
      DangerproductTableData: [],
      //危化品使用情况数据字典
      DangerproductDict: [],
      loading: false,
      validRules: {
        Name: [{ required: true, message: "名称必须填写" }],
      },
      formatdictId(value) {
        //表格下拉框循环显示数据字典名称
        let label = "";
        this.DangerproductDict.forEach(function (item) {
          if (value == item.value) {
            label = item.label;
          }
        });
        return label;
        // if (this.DangerproductDict.length > 0) {
        //   if (value == this.DangerproductDict[0].value) {
        //     return this.DangerproductDict[0].label;
        //   }
        //   if (value == this.DangerproductDict[1].value) {
        //     return this.DangerproductDict[1].label;
        //   }
        //   if (value == this.DangerproductDict[2].value) {
        //     return this.DangerproductDict[2].label;
        //   }
        //   if (value == this.DangerproductDict[3].value) {
        //     return this.DangerproductDict[3].label;
        //   }
        //   if (value == this.DangerproductDict[4].value) {
        //     return this.DangerproductDict[4].label;
        //   }
        //   if (value == this.DangerproductDict[5].value) {
        //     return this.DangerproductDict[5].label;
        //   }
        //   if (value == this.DangerproductDict[6].value) {
        //     return this.DangerproductDict[6].label;
        //   }
        //   return "";
        // }
      },
    };
  },
  mounted() {
    if (this.DangerproductListid) {
      this.GetDangerproductList(this.DangerproductListid);
      this.readDangerproductDict();
    }
  },
  methods: {
    ToastSuccess(res) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      var title = [];
      if (res.message == "Success") {
        title = "保存成功";
      } else {
        title = res.message;
      }
      Toast.fire({
        icon: "success",
        title: title,
      });
    },
    ToastWarning(res) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      var title = [];
      if (res.message == "False") {
        title = "保存出错";
      } else {
        title = res.message;
      }
      Toast.fire({
        icon: "warning",
        title: title,
      });
    },
    //获取危化品使用情况列表
    GetDangerproductList(id) {
      let that = this;
      that.loading = true;
      businessApi
        .GetDangerproductList({
          //入参为地址栏传入的ID值
          BusinessId: id,
        })
        .then((res) => that.GetDangerproductListSuccess(that, res))
        .finally(() => {
          that.loading = false;
        });
    },
    GetDangerproductListSuccess(that, res) {
      if (res.success) {
        that.DangerproductTableData = res.data.items;
        that.RtablePage.totalResult = res.data.totalCount;
      } else {
        console.log(res);
      }
    },
    //危化品使用情况列表新增
    async insertREvent() {
      if (this.DangerproductListid) {
        const $table = this.$refs.DangerproductTable;
        const newRecord = {
          BusinessId: this.id,
          DictId: "",
          MonthUsed: "",
          DayStorage: "",
        };
        const { row: newRow } = await $table.insert(newRecord);
        await $table.setActiveRow(newRow);
      } else {
        alert("请保存企业后新增！");
      }
    },
    saveREvent(row) {
      const DangerproductTableData = {
        businessId: this.DangerproductListid,
        dictId: row.dictId,
        monthUsed: row.dayStorage,
        dayStorage: row.dayStorage,
      };
      let that = this;
      if (row.id) {
        businessApi
          .UpdatedDangerproduct(row)
          .then((res) => that.UpdatedDangerproductSuccess(that, res))
          .finally(() => {});
      } else {
        businessApi
          .CreateDangerproduct(DangerproductTableData)
          .then((res) => that.CreateDangerproductSuccess(that, res))
          .finally(() => {});
        console.log(that);
      }
    },
    CreateDangerproductSuccess(that, res) {
      if (res.success) {
        that.ToastSuccess(res);
        this.$emit("close");
      } else {
        that.ToastWarning(res);
      }
    },
    UpdatedDangerproductSuccess(that, res) {
      if (res.success) {
        that.ToastSuccess(res);
        this.$emit("close");
      } else {
        that.ToastWarning(res);
      }
    },
    removeRRowEvent(row) {
      this.$swal({
        // title: "Are you sure?",
        text: "是否确定删除?",
        type: "info",
        icon: "warning",
        showCancelButton: true, //确定按钮
        buttonsStyling: false,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        confirmButtonClass: "btn m-r-5 btn-" + "info" + "", //按钮颜色设置
        cancelButtonClass: "btn btn-default", //按钮类型设置
      }).then((isConfirm) => {
        try {
          //判断 是否 点击的 确定按钮
          if (isConfirm.value) {
            let that = this;
            if (row.id) {
              businessApi
                .DeleteDangerproduct({ id: row.id })
                .then((res) => that.DeleteDangerproductSuccess(that, res, row))
                .finally(() => {});
            } else {
              const $table = this.$refs.DangerproductTable;
              $table.remove(row);
            }
          } else {
            console.log(isConfirm);
          }
        } catch (e) {
          alert(e);
        }
      });
    },
    DeleteDangerproductSuccess(that, res, row) {
      if (res.success) {
        const $table = this.$refs.DangerproductTable;
        $table.remove(row);
        res.message = "删除成功";
        that.ToastSuccess(res);
        console.log(res);
      } else {
        console.log(res);
      }
    },
    handlePageChange({ currentPage, pageSize }) {
      this.RtablePage.currentPage = currentPage;
      this.RablePage.pageSize = pageSize;
      //改变分页参数，重新请求Api
      this.GetDangerproductList();
    },
    //读取[特种设备]数据字典
    readDangerproductDict() {
      let that = this;
      let options = [];
      that.DangerproductDict = [];
      dictApi
        .getDictUrl({
          id: "3a03aa53-da5b-ba8a-ec4d-fbdf4e29e37c",
          bChild: false,
        })
        .then((res) => {
          // console.log('readDangerproductDict',res.data)
          res.data.forEach((element) => {
            options.push({
              label: element.name,
              value: element.id,
            });
          });
          that.DangerproductDict = options;
        });
    },
  },
};
</script>
